@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;300;500;700&display=swap);
.lds-ring {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 35px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 26px;
  height: 26px;
  margin: 4px;
  border: 4px solid skyblue;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: skyblue transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.rainbow-text {
    background-image: linear-gradient(to left, gold, pink, violet, lightblue, green, yellow, orange, red);
    -webkit-background-clip: text;

    color: transparent;
    
  }

  
* {
  font-family: "Inter", sans-serif;
}
html {
  padding-top: 6px;
}

.timer-part {
  overflow-y: hidden;
  background-image: linear-gradient(white, #e0e0e0);
  border: 1px solid #dfdfdf !important;
  height: 77px;
  margin: 1px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.15em;
  color: black;
}

/* make media query */
/* .contodo {
  flex-wrap: wrap;
}
.ododollar {
  margin-left: 30px;
} */

.transition-enter {
  opacity: 0;
}
.transition-enter.transition-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}
.transition-exit {
  display: none;
}
.transition-exit.transition-exit-active {
  display: none;
}

